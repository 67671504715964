.data-list div.css-ql19fo-MuiDataGrid-overlayWrapper,
.data-list div.css-1tmxkya {
  height: 40px !important;
}

.css-5oh42b-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-5oh42b-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-5oh42b-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-5oh42b-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.data-list span.css-cxb90s-MuiCircularProgress-root {
  color: #feb718 !important;
}
